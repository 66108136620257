import React from "react";

const Exp = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">EXPERIENCE</div>
				<div id="abt">
               
                <h2>Intern at Singtel Trustwave Cybersecurity Institute</h2>
                <h3>[5 Months] (September 2020 - February 2021)</h3>
                <p>
                    &gt; Set up attack scenarios involving Windows 10, Active Directory, and Kali<br/>
                    &gt; spoof phishing email, MSFVenom to plant malware to call back to c2, metasploit RCE attack
                    <a href="https://dev.to/boiledsteak/simple-remote-code-execution-on-ejs-web-applications-with-express-fileupload-3325" className="italic"> [read more]</a><br/>
                    &gt; Aid logistics for cybersecurity corporate trainings<br/>
                    &gt; Set up multiple windows workstations with active directory and join forest. Apply GPO. Connect to vSphere <br />
                    &gt; Automated arduous manual tent card generation with automated web app that modifies PDF programmatically 
                    <a href="https://dev.to/boiledsteak/automatic-pdf-form-filler-web-app-58j9" className="italic"> [read more]</a> <br />
                </p>
                <br />
              
                <h2>Devsecops Consultant at Statera Solutions</h2>
                <h3>[11 months] (August 2023 - July 2024)</h3>
                <p>
                    &gt; Set up devops environment as proof-of-concept to demonstrate Synopsys security tools integrated in a SDLC pipeline
                </p>
            
			</div>
			</div>
			
</div>
			
		
		</div>
	);
};

export default Exp;
