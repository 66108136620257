import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const Skills = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>		
			<div className="about-con">
				<div className="title">WHAT I OFFER</div>
				<div id="abt">
					<p>
					Skills and knowledge in,
					</p>
		
					<div className="bubble-con">
						<div className="bubble-row">
							{/* Lets try to draw these values from a database */}
							<div className="bubble">C</div><div className="bubble">C++</div><div className="bubble">Java</div><div className="bubble">Python</div>
						</div>
						<div className="bubble-row">
							<div className="bubble">HTML</div><div className="bubble">CSS</div><div className="bubble">NodeJS</div><div className="bubble">ReactJS</div><div className="bubble">MERN</div><div className="bubble">Flask</div><div className="bubble">JSP</div><div className="bubble">PHP</div><div className="bubble">XAMPP</div>
						</div>
						<div className="bubble-row">
							<div className="bubble">Mongo</div><div className="bubble">MySQL</div><div className="bubble">Oracle DB</div><div className="bubble">Dynamo DB</div>
						</div>
						<div className="bubble-row">
							<div className="bubble">Synopsys Blackduck SCA</div><div className="bubble">Synopsys Coverity SAST</div><div className="bubble">Synopsys Codesight</div><div className="bubble">Synopsys Software Risk Manager</div><div className="bubble">Synopsys Polaris SaaS</div>
						</div>
						<div className="bubble-row">
							<div className="bubble">Jenkins</div><div className="bubble">Github Actions</div><div className="bubble">Gitlab CI</div><div className="bubble">Snyk</div><div className="bubble">HCP Terraform</div><div className="bubble">Digital Ocean</div>
						</div>
					</div>
					<div>
						<p>
							- DevSecOps<br />
							- Linux, Kali, Ubuntu, RHEL, Windows, Active Directory<br />
							- VMWare ESXi, vSphere, Workstation, Docker<br />
							- Networking and Security<br />
							- Computer Law in Singapore<br />
							- Security Policy and Incident Management<br />
							- Telegram bots<br />
							- Adobe Photoshop, Illustrator, Premiere pro, After Effects
						</p>
						</div>
				</div>
			</div>
			</div>
	);
};

export default Skills;
