import React from "react";
import { NavLink } from "react-router-dom";

const About = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">ABOUT</div>
				<div id="abt">
					<p>
					Hi I'm Timothy. I am a cybersecurity student based in Singapore. My journey began when I enrolled into Singapore Polytechnic's Diploma in Infocomm Security Management course. A shot in the dark with wide-eyed optimism, I learnt not just cybersecurity concepts, but also programming, databases and networking. Amongst them all, one skill resonated with me the most — creating solutions.
					<br></br>
					<br></br>
					Manual, repetitive work goes against my nature. I believe automation, development, and security goes hand-in-hand. While working with Statera Solutions, I was introduced to DevOps. There is a real market demand for automated application security, and even more so via cloud computing, more commonly named "as a service". Perhaps SaaS solutions are where my interests align.
					<br></br>
					<br></br>
					Having completed National Service, I am now studying at Singapore Institute of Management, University of Wollongong's Bachelor's Degree in Computer Science. I look forward to exploring my place in the cybersecurity industry.
					</p>
				</div>
			</div>
			</div>
			

			
		
		</div>
	);
};

export default About;
