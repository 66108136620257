import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {

	const location = useLocation();

	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<div className="page">
			<header>
				<div className="headerCon">
					<div className="logo-con">
						<div className="thelogo">
							<NavLink to="/">
								<img
									id="logoPic"
									src={process.env.PUBLIC_URL + "/images/myface1.jpg"}
									alt=""
								/>
							</NavLink>
						</div>
						<div className="spacing1"></div>
						<div className="myname">
							<p>
								Timothy Mah
							</p>
						</div>
					</div>

					<div className="contact-deets">
						<div className="contact-deets-row">
							<a href="https://t.me/cplxamah">
								<p>
									Telegram me!
								</p>
								<img
									id="telelogotop"
									src={process.env.PUBLIC_URL + "/images/telegram-logo.svg"}
									alt=""
								/>
							</a>
							
						</div>
					</div>
					
				</div>
				<div className="sidebar">
					<ul>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/about">ABOUT</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/skills">WHAT I OFFER</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/exp">EXPERIENCE</NavLink></li>
						<li><NavLink className="bignavs" activeClassName="onPage" to="/certs">CERTIFICATION</NavLink></li>
					</ul>
				</div>
			
			</header>
			{/*Hamburger Menu*/}
			<div id="nav-container">
				<div className="bg"></div>
				<div className="button" tabindex="0">
				<span className="icon-bar"></span>
				<span className="icon-bar"></span>
				<span className="icon-bar"></span>
				</div>
				<div id="nav-content" tabindex="0">
					<div className="mobilemenu-photo-con">
						<img
							id="logoPic"
							src={process.env.PUBLIC_URL + "/images/myface1.jpg"}
							alt=""
						/>
						<a id="telegram-link" href="https://t.me/cplxamah">
							<p id="mobile-tele-me">
								Telegram me!
							</p>
							<img
								id="telelogo"
								src={process.env.PUBLIC_URL + "/images/telegram-logo.svg"}
								alt=""
							/>
						</a>
						
					</div>
					
				<ul>
				<li><NavLink className="navs" activeClassName="onPage" to="/about">ABOUT</NavLink></li>
				<li><NavLink className="navs" activeClassName="onPage" to="/skills">WHAT I OFFER</NavLink></li>
				<li><NavLink className="navs" activeClassName="onPage" to="/exp">EXPERIENCE</NavLink></li>
				<li><NavLink className="navs" activeClassName="onPage" to="/certs">CERTIFICATION</NavLink></li>
				</ul>
				</div>
			</div>
			
		
		</div>
	);
};

export default Header;
