import React from "react";


import Contact from "./pages/Contact";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Exp from "./pages/Exp";
import Certs from "./pages/Certs";
import Header from "./components/Header";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/about" exact>
            <Header />
            <About />
            <Footer />
          </Route>
          <Route path="/skills" exact>
            <Header />
            <Skills />
            <Footer />
          </Route>
          <Route path="/exp" exact>
            <Header />
            <Exp />
            <Footer />
          </Route>
          <Route path="/certs" exact>
            <Header />
            <Certs />
            <Footer />
          </Route>
        
          <Route path="/" exact>
            <Redirect to="/about" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
