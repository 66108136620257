import React from "react";


const Contact = () => {
  return (
    <div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
      <div className="header-spacing"></div>
	  <div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">ABOUT</div>
				<div id="abt">
					lets talk
				</div>
			</div>
		</div>
    </div>
  );
};

export default Contact;
